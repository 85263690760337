import AppRoutes from "./routes/index";
import ProvideAuth from "./context/Auth";
import Notification, { notify } from "react-notify-toast";
// import Notifications from "./templates/adminPortal/Socket";
import { useUserback } from "@userback/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Gleap from "gleap";
import { fetchIPAddress } from "./redux/classes/ipAddressslice";
// Main App
function App() {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const { show, hide, open } = useUserback();

  useEffect(() => {
    Gleap.initialize("EKa1LYppi62qK0R9TAC3nIRxMWgMzOSj");
    dispatch(fetchIPAddress());
  }, []);

  return (
    <div id="scroller" className="">
      {/* <Notifications/> */}
      <ProvideAuth>
        {/* <BrowserRouter> */}
        <Notification />
        <AppRoutes />
        {/* </BrowserRouter> */}
      </ProvideAuth>
    </div>
  );
}

export default App;
